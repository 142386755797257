<template>
  <div class="paylater-list" v-if="isReady">
    <vx-card class="accordin-card">
      <vs-collapse accordion>
        <vs-collapse-item ref="profileCollapseRef" open>
          <div slot="header">
            <h3 class="font-normal">Profile details <span class="mid-blue">*</span></h3>
          </div>

          <div class="flex mb-8">
            <div class="input-grp">
              <label for="productName" class="w-full text-xm font-normal"
              >Profile name <span class="required-text">*</span></label
              >
              <vs-input
                size="large"
                v-model="formData.name"
                data-vv-validate-on="blur"
                data-vv-as="profile name"
                v-validate="'required|max:100'"
                name="profileName"
                id="profileName"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('profileName')"
              >{{ errors.first("profileName") }}</span
              >
            </div>
            <div class="input-grp">
              <label for="productName" class="w-full text-xm font-normal">
                Profile description
              </label>
              <vs-textarea
                counter="250"
                maxlength="250"
                name="profileDescription"
                v-model="formData.description"
                id="profileDescription"
                @input="auto_grow('profileDescription')"
              ></vs-textarea>
              <span
                class="text-danger text-sm"
                v-show="errors.has('profileDescription')"
              >{{ errors.first("profileDescription") }}</span
              >
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item ref="profileFieldCollapseRef" open>
          <div slot="header">
            <h3 class="font-normal">Business information</h3>
          </div>
          <custom-fields
            v-on:savedCustomField="pushToCustomFields($event)"
            :customFieldValue="formData.dataFields"
            :type="'business-information'"
          ></custom-fields>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item ref="ownershipFieldCollapseRef" open>
          <div slot="header">
            <h3 class="font-normal">Business ownership</h3>
          </div>
          <custom-fields
            v-on:savedCustomField="pushToOwnershipCustomFields($event)"
            :customFieldValue="formData.businessOwnershipFields || []"
            :type="'business-ownership'"
          ></custom-fields>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <!-- paylater product ends here -->
    <div class="flex flex-wrap mt-10 items-center">
      <vs-button
        v-round
        class="ml-auto mr-5"
        size="large"
        @click="updateOnboardingProfileDetail"
      >Update</vs-button
      >
      <u> <a size="large" @click="$router.go(-1)">Cancel</a></u>
    </div>
  </div>
</template>

<script>
import CustomFields from "./CustomField.vue";
import {mapActions} from "vuex";

export default {
  name: "edit",
  components: {
    CustomFields
  },
  data() {
    return {
      profileId: "",
      isReady: false,
      formData: {}
    }
  },
  beforeMount() {
    this.getDetail();
  },
  mounted() {
    this.profileId = this.$route.params.onboardingProfileId;
    this.checkAdminLogin();
  },
  methods: {
    ...mapActions("onboardingAdmin", [
      "getOnboardingProfileDetail",
      "updateOnboardingProfile"
    ]),
    ...mapActions("admin", ["checkLogin"]),

    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
      this.$refs.profileCollapseRef.maxHeight = "none !important";
    },

    pushToCustomFields(customFieldList) {
      this.$refs.profileFieldCollapseRef.maxHeight = "none !important";
      this.formData.dataFields = customFieldList;
    },
    pushToOwnershipCustomFields(customFieldList) {
      this.$refs.ownershipFieldCollapseRef.maxHeight = "none !important";
      this.formData.businessOwnershipFields = customFieldList;
    },

    async getDetail() {
      this.$vs.loading();
      await this.getOnboardingProfileDetail(this.$route.params.onboardingProfileId)
        .then((result) => {
          
          this.formData = result.data.data;
          this.isReady = true;
        })
        .catch((err) => {
          
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async updateOnboardingProfileDetail() {
      try {
        const validation = await this.$validator.validateAll();
        if ( !validation ) return false;

        

        this.$vs.loading();
        this.updateOnboardingProfile({
          profileId: this.profileId,
          payload: this.formData
        })
          .then((res) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Onboarding profile has been updated successfully.",
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check-circle",
            });
            this.$router.push({ name: "onboarding-profile" });
          })
          .catch((err) => {
            this.$vs.loading.close();
            this.showPopUpErrorWithTitle = true;
          })
          .finally(() => {
            this.$vs.loading.close();
          })
      }
      catch (e) {
      }
      finally {

      }
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  }
}
</script>